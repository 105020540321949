.error {
    background-color: red;
    color: white;
}
.success {
    background-color: green;
    color:white;
}

.active {
    display: block;

    
    max-height: 200px;
    transition: 200px 5.0s ease-out;
    
}
.inactive{
    max-height: 0px;
    transition: 0 0.15s ease-out;
    display: none;
}