@tailwind base;
@tailwind components;
@tailwind utilities;

@media print {
    * {
       visibility: hidden;
       
    }
    .navigation {
         display: none;
         visibility: hidden;
    }
    .printable, .printable * {
     
       visibility: visible;
       font-family: 'Times New Roman', Times, serif;
        font-size: 12px;
        color: black;
    }
    .printable .text-3xl {
        font-size: 24px;
    }
    .printable {
       position: absolute;
       left: 0;
       top: 0;
    }
    .printable .skipprint {
       display: none;
    }
 }

